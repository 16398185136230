import { useEffect, useState } from "react";
import { getAllTradeViewDataApi } from "../../api/api";
export default function SetupTable({page,symbolType,symbolName}){
    const [liveData, setLiveData] = useState([]);



    useEffect(()=>{
        callApi()
    },[symbolType,symbolName])


    useEffect(()=>{
        if(page!==1){
            callApi2()
        }
    },[page])


    function callApi() {
        Promise.resolve(getAllTradeViewDataApi(page,symbolType,symbolName)).then(response => {
            if (response.status === "success") {
                setLiveData(response.data);
            }
        })
    }

    function callApi2() {
        Promise.resolve(getAllTradeViewDataApi(page,symbolType,symbolName)).then(response => {
            if (response.status === "success") {
                setLiveData([...liveData,...response.data]);
            }
        })
    }
    return(
        <>
        
        <table className="setup-table text-white">
            <thead>
                <tr>
                    <td  style={{ border:"2px solid  #222222"}}>SYMBOL</td>
                    <td  style={{ border:"2px solid  #222222"}}>ENTRY TYPE</td>
                    <td  style={{ border:"2px solid  #222222"}}>ENTRY PRICE</td>
                    <td  style={{ border:"2px solid  #222222"}}>TAKE PROFIT</td>
                    <td  style={{ border:"2px solid  #222222"}}>STOP LOSS</td>
                    <td  style={{ border:"2px solid  #222222"}}>TIME OF ENTRY</td>
                    <td  style={{ border:"2px solid  #222222"}}>WIN/LOSS</td>
                    <td  style={{ border:"2px solid  #222222"}}>CHART LINK</td>
                </tr>
            </thead>
            <tbody>

            {
                liveData.map((e,pos)=>
                <tr key={pos}>
                    <td style={{ border:"2px solid  #222222"}}>{e.symbol}</td>
                    <td style={{ border:"2px solid  #222222"}}>{e.direction}</td>
                    <td style={{ border:"2px solid  #222222"}}>{e.entry}</td>
                    <td style={{ border:"2px solid  #222222"}}>{e.tp}</td>
                    <td style={{ border:"2px solid  #222222"}}>{e.sl}</td>
                    <td style={{ border:"2px solid  #222222"}}>{e.date}</td>
                    <td style={{ border:"2px solid  #222222"}}>{e.exit_msg}</td>
                    <td style={{ border:"2px solid  #222222"}}><a href={e.chart_link}  rel="noreferrer" target="_blank" className="text-blue">{e.chart_link}</a></td>
                </tr>
                )
            }
                
            </tbody>
        </table>
        </>
    )
}