import { IMAGE_URL } from "../../constant/url";

export default function Header() {
    return (
        <>
            {/* <nav className="navbar navbar-light bg-dark px-4 sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand fs-2 text-white" href="/">
                        <img src={`${IMAGE_URL}point_capitalis.png`} alt="" width={250} height={80} className="d-inline-block align-text-top"/>
                        
                    </a>
                    <ul className="nav justify-content-end d-flex">
                        <li className="nav-item">
                            <a className="nav-link text-white" href="#">Login / Signup</a>
                        </li>
                    </ul>
                </div>
            </nav> */}
        </>
    )
}