import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeSearch from './components/HomeSearch/HomeSearch';
import SetupSearch from './components/SetupSearch/SetupSearch';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomeSearch />}></Route>
        <Route path='setup' element={<SetupSearch />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
