import { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { getAllTradeViewDataApi } from "../../api/api";
export default function SymbolBox({ selectedTab,page }) {
    // console.log(selectedTab.category,selectedTab.symbolName)
    return (
        <>
            <Row>
                <Col className="card py-3" style={{ backgroundColor: '#1D2226' }}>
                    <SymbolBoxUp symbolName={selectedTab.symbolName} categoryName={selectedTab.category} />
                </Col>
            </Row>
          <Row>
            <Col className="my-3" style={{height:'2px',background:'#fff'}}>
            </Col>
          </Row>
            <Row>
                <Col className="py-3">
                    <SymbolBoxDown categoryName={selectedTab.category} page={page} />
                </Col>
            </Row>
        </>
    )
}

function SymbolBoxUp({ symbolName, categoryName }) {
    const [liveData, setLiveData] = useState([]);
    useEffect(() => {
        callApi()
    }, [symbolName, categoryName])
    function callApi() {
        Promise.resolve(getAllTradeViewDataApi(1, categoryName, symbolName)).then(response => {
            if (response.status === "success") {
                setLiveData(response.data);
            }
        })
    }
    return (
        <>
            {
                liveData.slice(0,1).map((e, pos) =>
                    <div key={pos}>
                        <a href={e.chart_link} rel="noreferrer" className="text-blue" style={{ fontSize: '14px', textDecoration: 'none' }}>{e.content}</a>
                        <img src={`${e.chart_link}`} className="img rounded" width={'100%'} height={150} alt="trade image"></img>
                    </div>
                )
            }


        </>
    )
}

function SymbolBoxDown({ categoryName ,page}) {
    const [liveData, setLiveData] = useState([]);
    useEffect(() => {
        callApi()
    }, [categoryName,page])
    function callApi() {
        Promise.resolve(getAllTradeViewDataApi(page, categoryName, "")).then(response => {
            if (response.status === "success") {
                setLiveData([...liveData,...response.data]);
            }
        })
    }
    return (
        <>

            {
                liveData.map((e, pos) =>
                    <div key={pos} className="mt-3">
                        <a href={e.chart_link} rel="noreferrer" className="text-blue" style={{ fontSize: '14px', textDecoration: 'none' }}>{e.content}</a>
                        <img src={`${e.chart_link}`} className="img rounded" width={'100%'} height={150} alt="trade image"></img>
                    </div>
                )
            }

        </>
    )
}