import { memo, useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { getAllTradeViewDataApi } from "../../api/api";
function SymbolMain({ selectedTab,page }) {
    return (
        <>
           
            <Row>
                <Col className="py-3">
                    <SymbolBoxDown categoryName={selectedTab.category} page={page} />
                </Col>
            </Row>
        </>
    )
}




function SymbolBoxDown({ categoryName,page }) {
    const [liveData, setLiveData] = useState([]);
    console.log(page)
    useEffect(() => {
        callApi()
    }, [categoryName,page])
    function callApi() {
        Promise.resolve(getAllTradeViewDataApi(page, categoryName, "")).then(response => {
            if (response.status === "success") {
                setLiveData([...liveData,...response.data]);
            }
        })
    }
    return (
        <>

            {
                liveData.map((e, pos) =>
                    <div key={pos}>
                        <a href={e.chart_link} rel="noreferrer" target="_blank" className="text-blue" style={{ fontSize: '14px', textDecoration: 'none' }}>{e.content}</a>
                        <img src={`${e.chart_link}`} className="img rounded" width={'100%'} height={150} alt="trade image"></img>
                    </div>
                )
            }

        </>
    )
}

export default  memo(SymbolMain)