import { useState } from "react";
import { Row, Col } from "react-bootstrap"
import SetupTable from "./SetupTable";
export function TabMenu({selectCateory}) {
    const [symbolType, setSymbolType] = useState("Currencies");
    const [tabPos, setTabPos] = useState(0);
    const [tradeHeight, setTradeHeight] = useState(0)
    const [tradeViewPos, setTradViewPos] = useState(1)
    const [symbolName, setSymbolName] = useState("");
    const tabMenu = [
        { name: "Currencies" },
        { name: "US Stocks" },
        { name: "Crypto" },
        { name: "Indian Stocks" },
        { name: "Indices" },
    ]

    const tabClick = (name, pos) => {
        setTabPos(pos)
        setSymbolType(name)
        setTradViewPos(1);
        setTradeHeight(0)
        setSymbolName("")
        selectCateory({category:name})

    }

    function scrollFun(e) {
        let scrollHeight = e.target.scrollHeight - 200;
        let scrollVal = e.target.scrollTop + e.target.offsetHeight;
        if (scrollVal >= scrollHeight && scrollHeight > tradeHeight) {
            console.log(true)
            setTradeHeight(scrollHeight);
            setTradViewPos(tradeViewPos + 1)
        }
    }

    function onHandleChange(event){
        setSymbolName(event.target.value)
        selectCateory({symbolName:event.target.value})
        setTradViewPos(1);
        setTradeHeight(0)
    }

    return (
        <>
            <Row>
                <Col className="card p-2 mt-1" style={{ backgroundColor: '#1D2226' }}>
                    <ul className="nav fns-3 justify-content-center setup-menu">
                        {
                            tabMenu.map((e, pos) =>
                                <li className={tabPos === pos ? `text-blue` : `text-white`} key={pos} onClick={() => tabClick(e.name, pos)}>{e.name}</li>
                            )
                        }

                    </ul>
                </Col>
            </Row>

            <Row>
                <Col className="p-2 mt-1" >
                    <p className="text-center p-0 m-0">
                        <input type="text" placeholder="Enter Symbols" className="searchBox1" onChange={onHandleChange}></input>
                    </p>
                </Col>
            </Row>

         
            <Row>
                <Col className="card p-4 mt-1 table-scroll bar" style={{ backgroundColor: '#1D2226' }} onScroll={scrollFun}>
                    <SetupTable page={tradeViewPos} symbolType={symbolType} symbolName={symbolName}/>
                </Col>
            </Row>
       
        </>
    )
}