import { useState,useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAllTradeViewDataApi } from "../../api/api";
export default function SearchBar({ searchBox }) {
    const navigate = useNavigate()
    const [inputData,setInputData] = useState("EURUSD");
    function handleChange(event) {
        searchBox({ symbolName: event.target.value })
        setInputData(event.target.value)
    }

    function category(cat){
        searchBox({ category: cat })
    }
    return (
        <>
            <Row className="justify-content-center">
                <Col md={9} className="py-2 my-3">
                    <p className="text-white fs-3 open-sans text-start mx-4 mt-4">Technical Analysis</p>
                    <Row className="align-items-center justify-content-center">
                        <Col>
                            <input type="search" placeholder="Enter any symbol name" className="searchBox" onChange={handleChange}></input>
                            <input type="button" value="Search" className="btn btn-search mx-1"></input>
                        </Col>
                    </Row>
                    <p className="text-white fs-6 open-sans mt-4 text-center" style={{ cursor: 'pointer' }} onClick={() => navigate("/setup")}>
                        Machine Learning based analysis for <span className="text-red">30 currency pairs</span>, <span className="text-cyan">1000 US Stock</span>, 270 Indian stocks, 19 Indices,
                    </p>
                  
                   

                </Col>

            </Row>

            <Row className="justify-content-center">
                <Col md={9}>
                    <SymbolBoxUp  inputData = {inputData} categoryName={"No"} category ={category}/>
                </Col>
                <Col md={9} className="mt-3">
                <p className="text-center ">
                    <iframe className="mobilebi" src="https://app.powerbi.com/view?r=eyJrIjoiMTU2MTk0NTItOWMxNS00ZmNmLTk0MDgtMGY0NjBjOTE5MzlmIiwidCI6ImEyNzEyNDE2LTU3NDktNDdlNy1iODExLTZkMDZmMTExOWJkNyJ9" scrolling="no" frameborder="0"  style={{width:'100%'}}>

                    </iframe>
                    </p>
                </Col>
            </Row>
        </>
    )
}



function SymbolBoxUp({ inputData, categoryName ,category}) {
    const [liveData, setLiveData] = useState([]);
    useEffect(() => {
        callApi()
    }, [inputData, categoryName])
    function callApi() {
        Promise.resolve(getAllTradeViewDataApi(1, categoryName, inputData)).then(response => {
            if (response.status === "success") {
                setLiveData(response.data);
                console.log(response.data[0].symbol_type)
                category(response.data[0]?.symbol_type)
            }
        })
    }
    return (
        <>
            {
                liveData.slice(0, 1).map((e, pos) =>
                    <div key={pos}>
                        <a href={e.chart_link} rel="noreferrer" target="_blank" className="text-blue" style={{ fontSize: '14px', textDecoration: 'none' }}>{e.content}</a>
                        <img src={`${e.chart_link}`} className="img rounded" width={'100%'} height={300} alt="trade image"></img>
                    </div>
                )
            }


        </>
    )
}