import { API_URL } from "../constant/url";
import { ROUTE } from "../routes/routes";
const METHOD = {
    GET:"GET",
    POST:"POST",
    PUT:"PUT",
    DELETE:"DELETE"
}
export const getAllTradeViewDataApi = async (pageNo,symbolType,symbolName)=>{
    const HeadersObj = {
        method:METHOD.GET,
    }
    const response = await fetch(`${API_URL}${ROUTE.getAllTradeViewData}?pageNo=${pageNo}&symbol_type=${symbolType}&symbol_name=${symbolName}`,HeadersObj);
    return  await response.json();
}