import { Col, Container,Row } from 'react-bootstrap'
import { TabMenu } from './TabMenu'
import './SetupSearch.css'
import Header from '../Layout/Header'
import SymbolBox from './SymbolBox'
import { useState } from 'react'
export default function SetupSearch(){
    const [selectedTab,setSelectedTab]  = useState({symbolName:"",category:"Currencies"})
    const [tradeHeight, setTradeHeight] = useState(0)
    const [tradeViewPos, setTradViewPos] = useState(1)
    function selectCateory(data){
        setSelectedTab({...selectedTab,...data})
    }

    function scrollFun(e) {
        let scrollHeight = e.target.scrollHeight - 200;
        let scrollVal = e.target.scrollTop + e.target.offsetHeight;
        if (scrollVal >= scrollHeight && scrollHeight > tradeHeight) {
            console.log(true)
            setTradeHeight(scrollHeight);
            setTradViewPos(tradeViewPos + 1)
        }
    }

    return(
        <>
        <Header />
            <Container fluid>
                <Row>
                    <Col md={8} className='px-4'>
                       <TabMenu selectCateory = {selectCateory}/>
                    </Col>
                    <Col md={4} className='px-4 table-scroll bar' onScroll={scrollFun}>
                        <SymbolBox selectedTab = {selectedTab}  page={tradeViewPos}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}