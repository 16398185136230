import { Col, Container,Row } from 'react-bootstrap'
import './HomeSearch.css'
import SearchBar from './SearchBar'
import Header from '../Layout/Header'
import SymbolMain from './SymbolMain'
import { useState } from 'react'
export default function HomeSearch(){
    const [selectedTab,setSelectedTab]  = useState({symbolName:"",category:"Currencies"})
    const [tradeHeight, setTradeHeight] = useState(0)
    const [tradeViewPos, setTradViewPos] = useState(1)
    function searchBox(data){
        setSelectedTab({...selectedTab,...data})
    }
    function scrollFun(e) {
        let scrollHeight = e.target.scrollHeight - 200;
        let scrollVal = e.target.scrollTop + e.target.offsetHeight;
        if (scrollVal >= scrollHeight && scrollHeight > tradeHeight) {
            setTradeHeight(scrollHeight);
            setTradViewPos(tradeViewPos + 1)
        }
    }
    return(
        <>
        <Header />
            <Container fluid>
                <Row>
                    <Col md={8} className='px-4'>
                        <SearchBar searchBox = {searchBox}/>
                    </Col>
                    <Col md={4} className='px-4 table-scroll bar' onScroll={scrollFun}>
                        <SymbolMain page={tradeViewPos}  selectedTab = {selectedTab}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}